import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  assignMarketingToCabang,
  getAllCabang,
  getAllMarketing,
  tambahKaryawan,
} from "../../../stores";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  TextField,
  Stack,
  ButtonBase,
  InputAdornment,
} from "@mui/material";
import { Text, TextInput } from "../../../component";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

function Marketing() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [listCabang, setListCabang] = React.useState([]);
  const [idCabang, setIdCabang] = React.useState("");
  const [idMarketing, setIdMarketing] = React.useState("");
  const [openDialogPenempatan, setOpenDialogPenempatan] = React.useState(false);
  const [listMarketing, setListMarketing] = React.useState([]);
  const [openDialogTambahKaryawan, setOpenDialogTambahKaryawan] =
    React.useState(false);
  const [nama, setNama] = React.useState("");
  const [nopeg, setNopeg] = React.useState("");
  const [validasi, setValidai] = React.useState(false);

  React.useEffect(() => {
    getAllMarketing(dispatch);
    getAllCabang(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = karyawan.responGetCabang.map((val, index) => {
      return {
        ...val,
        label: val.namaCabang,
      };
    });
    setListCabang(tmp);
  }, [karyawan.responGetCabang]);

  const handleClickOpenPenempatan = () => {
    setOpenDialogPenempatan(true);
  };

  const handleClosePenempatan = () => {
    setOpenDialogPenempatan(false);
  };

  const handleClickOpenTambahKaryawan = () => {
    setOpenDialogTambahKaryawan(true);
  };

  const handleCloseTambahKaryawan = () => {
    setOpenDialogTambahKaryawan(false);
  };

  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "No.Pegawai",
      selector: (row) => row.nopeg,
      sortable: true,
    },
    {
      name: "Nama Karyawan",
      selector: (row) => row.nama,
      sortable: true,
    },
    {
      name: "Jabatan",
      minWidth: "200px",
      selector: (row) => row.jabatan,
    },
    {
      name: "Penempatan",
      minWidth: "450px",
      selector: (row) =>
        !row.penempatan ? (
          localStorage.getItem("status") === "admin" ? (
            <Button
              color="success"
              startIcon={<PersonPinCircleIcon />}
              variant="contained"
              size="small"
              onClick={() => {
                handleClickOpenPenempatan();
                setIdMarketing(row._id);
              }}
              style={{ textTransform: "none", marginLeft: 10 }}
            >
              Penempatan
            </Button>
          ) : (
            "Belum Penempatan"
          )
        ) : (
          <>
            <Stack direction={"row"} spacing={2}>
              <Box sx={{ flexGrow: 1, width: 300 }}>
                {row.penempatan.namaCabang}
              </Box>
              {localStorage.getItem("status") === "admin" ? (
                <Button
                  startIcon={<PersonPinCircleIcon />}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleClickOpenPenempatan();
                    setIdMarketing(row._id);
                  }}
                  style={{ textTransform: "none", marginLeft: 10 }}
                >
                  Ubah Penempatan
                </Button>
              ) : null}
            </Stack>
          </>
        ),
    },
  ];

  const submitAssign = () => {
    assignMarketingToCabang(dispatch, {
      _idMarketing: idMarketing,
      _idCabang: idCabang,
    }).then((response) => {
      if (response === 200) {
        getAllMarketing(dispatch);
        getAllCabang(dispatch);
        setOpenDialogPenempatan(false);
      }
    });
  };

  const submitMarketing = () => {
    if (nama === "" || nopeg === "") {
      setValidai(true);
    } else {
      setValidai(false);
      tambahKaryawan({
        nama: nama,
        nopeg: nopeg,
        jabatan: "Sales Marketing",
      }).then((response) => {
        if (response === 200) {
          getAllMarketing(dispatch);
          getAllCabang(dispatch);
          setOpenDialogTambahKaryawan(false);
        }
      });
    }
  };
  React.useEffect(() => {
    let tmp = [];
    karyawan.responGetMarketing &&
      karyawan.responGetMarketing.map((val) => {
        return tmp.push(val);
      });
    setListMarketing(tmp);
  }, [karyawan.responGetMarketing]);

  //Filter
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = listMarketing.filter(
    (item) =>
      item.nopeg && item.nopeg.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <>
        {localStorage.getItem("status") === "admin" ? (
          <Box flexGrow={1}>
            <Button
              color="primary"
              // startIcon={<PersonPinCircleIcon />}
              variant="contained"
              size="small"
              onClick={handleClickOpenTambahKaryawan}
              style={{ textTransform: "none", marginBottom: 20 }}
            >
              Tambah Marketing
            </Button>
          </Box>
        ) : null}
        <Box sx={{ width: "200px" }} marginBottom={3}>
          <TextInput
            id="standard-basic"
            variant="standard"
            type={"text"}
            onChange={(e) => setFilterText(e.target.value)}
            fullWidth={true}
            InputProps={{
              style: {
                fontSize: 12,
                fontWeight: "400",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={"Cari Nomor Pegawai"}
          />
        </Box>
      </>
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        customStyles={customStyles}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
      />

      <Dialog
        open={openDialogPenempatan}
        onClose={handleClosePenempatan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction={"row"} alignContent={"center"}>
            <Box sx={{ flexGrow: 1 }}>
              <b>Penempatan</b>
            </Box>
            <ButtonBase onClick={handleClosePenempatan}>
              <CloseIcon />
            </ButtonBase>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ paddingY: "20px" }}>
          <Box sx={{ marginTop: "20px" }}>
            <Autocomplete
              // disablePortal
              id="combo-box-demo"
              options={listCabang}
              sx={{ width: 500 }}
              renderInput={(params, index) => (
                <TextField {...params} label="Nama Cabang" />
              )}
              onChange={(event, newValue) => {
                setIdCabang(newValue._id);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={submitAssign}
            style={{
              textTransform: "none",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogTambahKaryawan}
        onClose={handleCloseTambahKaryawan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction={"row"} alignContent={"center"}>
            <Box sx={{ flexGrow: 1 }}>
              <b>Tambah Sales Marketing</b>
            </Box>
            <ButtonBase onClick={handleCloseTambahKaryawan}>
              <CloseIcon />
            </ButtonBase>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ paddingY: "20px" }}>
          <Box sx={{ marginTop: "20px" }}>
            <TextField
              id="outlined-basic"
              label="Nama Karyawan"
              variant="outlined"
              size="small"
              type={"text"}
              value={nama}
              onChange={(e) => {
                setNama(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <TextField
              id="outlined-basic"
              label="Nomer Karyawan"
              variant="outlined"
              size="small"
              type={"text"}
              value={nopeg}
              onChange={(e) => {
                setNopeg(e.target.value);
              }}
            />
          </Box>
          {validasi ? (
            <Box sx={{ marginTop: "10px" }}>
              <Text
                text={"*Nama atau No Karyawan tidak boleh kosong"}
                sx={{ color: "red", fontSize: 11 }}
              />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={submitMarketing}
            style={{
              textTransform: "none",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Marketing;
