import React from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Paper,
  Stack,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  ButtonBase,
  Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Text } from "../../component";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  getAllCabang,
  getAllPenyaluran,
  uploadPenyaluranMarketing,
  getAllMarketing,
  getAllDirectSales,
  editPenyaluranMarketing,
  getAllPenyaluranMarketing,
} from "../../stores";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import { RangeDatePicker } from "react-google-flight-datepicker";

function LaporanMarketing() {
  const dispatch = useDispatch();
  const karyawan = useSelector((state) => state.karyawanReducer);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [uploadPenyaluran, setUploadPenyaluran] = React.useState(null);
  const [namaFile, setNamaFile] = React.useState("");

  //form

  const [startDate, setStartDate] = React.useState(
    new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1).setMinutes(
        new Date().getTimezoneOffset() * -1
      )
    )
  );
  const [endDate, setEndDate] = React.useState(
    new Date(new Date().setMinutes(new Date().getTimezoneOffset() * -1))
  );
  const [idPenyaluran, setIdPenyaluran] = React.useState("");
  const [nopegawai, setNoPegawai] = React.useState("");
  const [namaCabang, setNamaCabang] = React.useState("");
  const [marketing, setMarketing] = React.useState("");
  const [penyaluran, setPenyaluran] = React.useState("");
  const [note, setNote] = React.useState("");
  const [historyEdit, setHistoryEdit] = React.useState({});

  //grid
  const [colom1, setColom1] = React.useState(12);
  const [colom2, setColom2] = React.useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [messageAlert, setMessageAlert] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [typeAlert, setTypeAlert] = React.useState("success");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
    setMessageAlert(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Box onClick={onClick}>
      <TextField
        value={moment(value).format("DD MMMM YYYY")}
        variant="outlined"
        label={"Tanggal Penyaluran"}
        // type={"date"}
        size="small"
        fullWidth={true}
      />
    </Box>
  ));
  const customStyles = {
    rows: {
      style: {
        height: 36, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 36,
        backgroundColor: "#F8F8F8",
        fontFamily: "Poppins",
        justifyContent: "left",
      },
    },
    headCells: {
      style: {
        minHeight: 36,
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    cells: {
      style: {
        minHeight: 36,
      },
    },
  };
  const columns = [
    {
      name: "No. Pegawai",
      sortable: true,
      selector: (row) => row.nopeg,
    },
    {
      name: "Nama",
      sortable: true,
      selector: (row) => row.nama,
    },
    {
      name: "Target",
      selector: (row) => (
        <NumericFormat
          value={!row.nominal ? 0 : row.nominal}
          thousandSeparator={true}
          prefix={"Rp"}
          displayType={"text"}
        />
      ),
    },
    {
      name: "Penyaluran",
      selector: (row) => (
        <NumericFormat
          value={
            row.totalPenyaluran
            // row.penyaluran.length != 0
            //   ? Math.round(row.penyaluran[0].totalPenyaluran)
            //   : 0
          }
          thousandSeparator={true}
          prefix={"Rp"}
          displayType={"text"}
        />
      ),
    },
    // {
    //   name: "Nama Cabang",
    //   sortable: true,
    //   selector: (row) => row.cabang,
    // },
    // {
    //   name: "Tanggal Penyaluran",
    //   sortable: true,
    //   selector: (row) => moment(row.penyaluranTanggal).format("DD MMMM YYYY"),
    // },
    // {
    //   name: "Penyaluran",
    //   selector: (row) => (
    //     <NumericFormat
    //       value={row.nominal}
    //       thousandSeparator={true}
    //       prefix={"Rp"}
    //       displayType={"text"}
    //     />
    //   ),
    // },
    // {
    //   name: "Aksi",
    //   minWidth: "250px",
    //   selector: (row) => (
    //     <>
    //       <Button
    //         onClick={() => {
    //           handleClickOpenEdit();
    //           console.log(row);
    //           setIdPenyaluran(row._id);
    //           setNoPegawai(row.nopeg);
    //           setNamaCabang(row.cabang);
    //           setMarketing(row.nama);
    //           setPenyaluran(row.nominal);
    //           setStartDate(new Date(row.penyaluranTanggal));
    //         }}
    //         variant="contained"
    //         sx={{ textTransform: "none" }}
    //       >
    //         Edit
    //       </Button>
    //       <Button
    //         color="success"
    //         onClick={() => {
    //           handleClickOpenHistory();
    //           setHistoryEdit(row);
    //           console.log(row);
    //         }}
    //         variant="contained"
    //         sx={{ textTransform: "none", marginX: 2 }}
    //       >
    //         History
    //       </Button>
    //     </>

    //   ),
    // },
  ];

  const submit = (e) => {
    e.preventDefault();
    let fd = new FormData();
    fd.append("excel", uploadPenyaluran);
    uploadPenyaluranMarketing(fd).then((response) => {
      if (response === 200) {
        handleClose();
        setUploadPenyaluran(null);
        setNamaFile("");
        getAllPenyaluran(dispatch);
      }
    });
  };

  // React.useEffect(() => {
  //   getAllPenyaluran(dispatch);
  //   getAllCabang(dispatch);
  //   getAllMarketing(dispatch);
  //   getAllDirectSales(dispatch);
  // }, []);

  React.useEffect(() => {
    getAllPenyaluranMarketing(dispatch, {
      from: startDate.getTime(),
      to: endDate.getTime(),
    });
  }, [startDate, endDate]);
  return (
    <div>
      {/* <Stack direction={"row"} sx={{ marginBottom: 10 }}>
        <Box width={"50%"} height={250}>
          <Text
            text={"Best Performance"}
            sx={{
              fontSize: 16,
              color: "#000000",
              fontWeight: "600",
              marginBottom: 1,
            }}
          />
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={karyawanTerbaik}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                label={{ value: "Milyar", angle: -90, position: "insideLeft" }}
              />
              <Tooltip />
              <Legend />

              <Bar dataKey="Marketing" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
        <Box width={"50%"} height={250}>
          <Text
            text={"Under Performance"}
            sx={{
              fontSize: 16,
              color: "#000000",
              fontWeight: "600",
              marginBottom: 1,
            }}
          />
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={karyawanTerburuk}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                label={{ value: "Milyar", angle: -90, position: "insideLeft" }}
              />
              <Tooltip />
              <Legend />

              <Bar dataKey="Marketing" fill="red" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Stack> */}

      <Box width={"40%"} marginTop={"20px"} marginBottom={"20px"}>
        <Text
          text={"Filter Tanggal"}
          sx={{ fontSize: 18, color: "#000000", fontWeight: "600" }}
        />
        <RangeDatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={(startDate, endDate) => {
            // console.log(
            //   new Date(
            //     startDate.setMinutes(new Date().getTimezoneOffset() * -1)
            //   )
            // );

            setStartDate(
              new Date(
                startDate.setMinutes(new Date().getTimezoneOffset() * -1)
              )
            );
            setEndDate(
              new Date(endDate.setMinutes(new Date().getTimezoneOffset() * -1))
            );
          }}
          dateFormat="DD/MM/YYYY"
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          disabled={false}
          className="my-own-class-name"
          startWeekDay="monday"
        />
      </Box>

      <Box sx={{ border: 2, borderColor: "#E0E0E0" }}>
        <DataTable
          columns={columns}
          data={karyawan.responPenyaluranMarketing}
          pagination
          customStyles={customStyles}
        />
      </Box>

      {/* <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={typeAlert}
          sx={{ width: "100%" }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Box>
        <Button
          color="success"
          // startIcon={<PersonPinCircleIcon />}
          variant="contained"
          size="small"
          onClick={handleClickOpen}
          style={{ textTransform: "none", marginLeft: 10, marginBottom: 20 }}
        >
          Upload Penyaluran
        </Button>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={colom1}>
          <Box sx={{ border: 2, borderColor: "#E0E0E0" }}>
            <DataTable
              columns={columns}
              data={karyawan.responPenyaluran}
              pagination
              customStyles={customStyles}
            />
          </Box>
        </Grid>
      </Grid> */}

      {/* <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Upload penyaluran"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack direction={"row"} alignItems={"center"}>
            <Button
              color="success"
              // startIcon={<PersonPinCircleIcon />}
              variant="contained"
              size="small"
              component="label"
              // onClick={() => {}}
              onChange={(e) => {
                e.preventDefault();
                setUploadPenyaluran(e.target.files[0]);
                setNamaFile(e.target.files[0].name);
              }}
              style={{
                textTransform: "none",
                marginRight: 10,
              }}
            >
              Upload Penyaluran
              <input hidden type="file" />
            </Button>
            <Box>
              <Text
                text={namaFile === "" ? "Tidak ada file" : namaFile}
                sx={{ color: "grey" }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Edit penyaluran"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ paddingTop: "15px" }}>
            <Box>
              <TextField
                disabled
                fullWidth={true}
                id="outlined-basic"
                label="No Pegawai"
                variant="outlined"
                size="small"
                value={nopegawai}
              />
            </Box>
            <Box marginTop={3}>
              <TextField
                disabled
                fullWidth={true}
                id="outlined-basic"
                label="Marketing"
                variant="outlined"
                size="small"
                value={marketing}
              />
            </Box>
            <Box marginTop={3}>
              <TextField
                disabled
                fullWidth={true}
                id="outlined-basic"
                label="Cabang"
                variant="outlined"
                size="small"
                value={namaCabang}
              />
            </Box>

            <Box marginTop={3}>
              <DatePicker
                selected={startDate}
                customInput={<ExampleCustomInput />}
                onChange={(date) => setStartDate(date)}
              />
            </Box>
            <Box marginTop={3}>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                label="Penyaluran"
                variant="outlined"
                size="small"
                type={"number"}
                value={penyaluran}
                onChange={(e) => {
                  setPenyaluran(e.target.value);
                }}
              />
            </Box>

            <Box marginTop={5}>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                label="Keterangan"
                variant="outlined"
                size="small"
                multiline
                rows={4}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (note === "") {
                setTypeAlert("warning");
                setMessageAlert("Keterangan tidak boleh kosong!");
                setOpenAlert(true);
              } else {
                editPenyaluranMarketing({
                  nominal: penyaluran,
                  tanggal: startDate.getTime(),
                  keterangan: note,
                  idPenyaluran: idPenyaluran,
                }).then((val) => {
                  if (val === 200) {
                    handleCloseEdit();
                    getAllPenyaluran(dispatch);
                    setTypeAlert("success");
                    setMessageAlert("Berhasil edit data!");
                    setOpenAlert(true);
                    setNote("");
                    setIdPenyaluran("");
                    setPenyaluran("");
                  } else {
                    handleCloseEdit();
                    getAllPenyaluran(dispatch);
                    setTypeAlert("error");
                    setMessageAlert("Gagal edit data!");
                    setOpenAlert(true);
                    setNote("");
                    setIdPenyaluran("");
                    setPenyaluran("");
                  }
                });
              }

              console.log(startDate.getTime(), penyaluran, note);
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"Upload Data Nasabah"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack direction={"row"} alignItems={"center"}>
            <Button
              color="success"
              // startIcon={<PersonPinCircleIcon />}
              variant="contained"
              size="small"
              component="label"
              // onClick={() => {}}
              onChange={(e) => {
                e.preventDefault();
                setUploadPenyaluran(e.target.files[0]);
                setNamaFile(e.target.files[0].name);
              }}
              style={{
                textTransform: "none",
                marginRight: 10,
              }}
            >
              Upload Data Nasabah
              <input hidden type="file" />
            </Button>
            <Box>
              <Text
                text={namaFile === "" ? "Tidak ada file" : namaFile}
                sx={{ color: "grey" }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={openHistory}
        onClose={handleCloseHistory}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Text
            text={"History Edit"}
            sx={{
              fontWeight: "600",
              fontSize: 18,
              color: "#004591",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container direction={"row"}>
            <Grid xs={6}>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={5}>
                    <Text
                      text={"No Pegawai"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Text
                      text={historyEdit.nopeg}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={5}>
                    <Text
                      text={"Nama"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Text
                      text={historyEdit.nama}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={5}>
                    <Text
                      text={"Cabang"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Text
                      text={historyEdit.cabang}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={5}>
                    <Text
                      text={"Tgl Penyaluran"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Text
                      text={moment(historyEdit.penyaluranTanggal).format(
                        "DD MMMM YYYY"
                      )}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid
                  container
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid xs={5}>
                    <Text
                      text={"Penyaluran"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Text
                      text={":"}
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Text
                      text={
                        <NumericFormat
                          value={historyEdit.nominal}
                          thousandSeparator={true}
                          prefix={"Rp"}
                          displayType={"text"}
                        />
                      }
                      sx={{
                        fontWeight: "400",
                        fontSize: 12,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box marginTop={3}>
            <Text
              text={"History Edit"}
              sx={{ color: "#004591", fontWeight: "bold", fontSize: 16 }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            {historyEdit.historyEdit && historyEdit.historyEdit.length != 0 ? (
              historyEdit.historyEdit.map((val, index) => {
                let tanggal = moment(val.updatedAt).format("DD MMMM YYYY");
                let waktu = moment(val.updatedAt).format("HH:mm");
                let newKata = val.keterangan.replace(
                  "TTANGGALL",
                  `${tanggal} pada pukul ${waktu}`
                );
                return (
                  <Stack direction={"row"} spacing={1} marginTop={1}>
                    <Box>
                      <Text
                        text={`${index + 1}.`}
                        sx={{ color: "grey", fontSize: 12 }}
                      />
                    </Box>
                    <Box>
                      <Text
                        text={newKata}
                        sx={{ color: "grey", fontSize: 12 }}
                      />
                    </Box>
                  </Stack>
                );
              })
            ) : (
              <Box marginTop={1}>
                <Text
                  text={"Tidak ada history edit data"}
                  sx={{ color: "grey", fontSize: 12 }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHistory} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}

export default LaporanMarketing;
