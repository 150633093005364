import { baseAxios } from "../index";

export async function login(dispatch, data, history) {
  let body = {
    idPerusahaan: "162",
    nopeg: data.nopeg,
    password: data.password,
  };
  try {
    const response = await baseAxios.post("user/loginWeb", body);
    dispatch({ type: "SET_LOGIN", data: response.data });
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("status", response.data.data.jabatan);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function logout(dispatch, data, history) {
  localStorage.removeItem("token");
  localStorage.removeItem("status");
  window.location.reload();
}
