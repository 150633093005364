import React from "react";
import DataTable from "react-data-table-component";
import { updateKaryawan } from "../../../stores";
import { Button, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Supervisor from "./spv";
import Marketing from "./marketing";
import DirectSales from "./directSales";

function AllKaryawan() {
  //   React.useEffect(() => {
  //     updateKaryawan();
  //   }, []);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Supervisor" value="1" sx={{ textTransform: "none" }} />
            <Tab label="Marketing" value="2" sx={{ textTransform: "none" }} />
            {/* <Tab
              label="Direct Sales"
              value="3"
              sx={{ textTransform: "none" }}
            /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <Supervisor />
        </TabPanel>
        <TabPanel value="2">
          <Marketing />
        </TabPanel>
        {/* <TabPanel value="3">
          <DirectSales />
        </TabPanel> */}
      </TabContext>
    </Box>
  );
}

export default AllKaryawan;
